import { Item } from "../../SimpleComponents/Item";
import { variables } from "../../Data/Variables";
import { CompanyHeader } from "../../SimpleComponents/CompanyHeader";
import Emblem from "../../../Images/Emblems/Emblem UUK Coal.png"
import { useEffect, useState } from "react";
import { ItemDescription } from "../../SimpleComponents/ItemDescription";
import { CoalData } from "../../Data/ItemsData";
import "./Coals.scss";
import { WorkFacts, WorkSteps } from "../../SimpleComponents/WorkSteps/WorkSteps";

const Coals = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const photoPath = variables.PHOTO_URL

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const response = await fetch(variables.API_URL + 'Coal')
            const data = await response.json()
            setData(data)
            setLoading(false)
        }

        fetchData()
    }, [])
    const coalsGrid = data.map((coal) => (
        <Item className="col" key={coal.CoalId} src={photoPath + coal.CoalImage} name={coal.CoalName}>
            <ItemDescription title={CoalData[0].title} value={coal.CoalSize} unit={CoalData[0].unit} />
            <ItemDescription title={CoalData[1].title} value={coal.CoalHeat} unit={CoalData[1].unit} />
            <ItemDescription title={CoalData[2].title} value={coal.CoalMoisture} unit={CoalData[2].unit} />
            <ItemDescription title={CoalData[3].title} value={coal.CoalAsh} unit={CoalData[3].unit} />
            <ItemDescription title={CoalData[4].title} value={coal.CoalSulfur} unit={CoalData[4].unit} />
            <ItemDescription title={CoalData[5].title} value={coal.CoalVolatile} unit={CoalData[5].unit} />
        </Item>))

    return (
        <>
            <section className="container">
                <CompanyHeader 
                    title="Реализация угля по Иркутской области" 
                    src={Emblem} 
                    description="Компания УУК-Уголь занимается поставками сертифицированного каменного угля в Иркутской области."
                    number = " +7 (924) 538-88-80"
                    email = " klevcov.eo@mail.ru">
                    <section>
                        <WorkSteps
                            firstStep = "Обращение"
                            firstStepInfo = "Оформим заявку при первом вашем обращении" 
                            secondStep = "Оплата" 
                            secondStepInfo = "Оплатите счет любым удобным для вас способом." 
                            thirdStep = "Доставка" 
                            thirdStepInfo = "Доставим в любое удобное для вас время и место." 
                        />
                    </section>
                    <section className="row-facts green-background mt-3 mb-3">
                        <WorkFacts icon="bi bi-clock" title="Оптом / Розницей" />
                        <WorkFacts icon="bi bi-credit-card" title="Любой способ оплаты" />
                        <WorkFacts icon="bi bi-star" title="Доставка / Самовывоз" />
                    </section>
                </CompanyHeader>
            </section>
            <section className="container text-center">
                <h1 className="p-5">Ассортимент</h1>
                <div className="row w-75">
                    {!loading && (coalsGrid)}
                    <h3>Данные не загрузились...</h3>
                </div>
            </section>
        </>
    );
}
export { Coals };
import { useRef } from "react";
import emailjs from "emailjs-com";
import "./ContactUsForm.scss";

const ContactUsForm = () => {

    const form = useRef();
    const formBlock = document.getElementById('form');

    const sendEmail = (event) =>{
        event.preventDefault();
        
        let error = handleValidation(form);

        if(error === 0){
           
            formBlock.classList.add('_sending');

            emailjs.sendForm('service_bxejzwi', 'template_zrty75g', form.current, 'WnXjWRchaorcqjPhL')
            .then((result) => {

                alert("Бандероль")
                form.current.reset();
                formBlock.classList.remove('_sending');

            }, (error) => {

                alert("Ошибка")
            });
        }
       
    };

    const handleValidation = (form) =>
    {
        let error = 0;
        let inputs = document.querySelectorAll('._req');
        
        for (let index = 0; index < inputs.length; index++) {
            const input = inputs[index];
            formRemoveError(input);
  
            
            if(input.classList.contains('_name'))
            {
                if(typeof input !== "undefined")
                {
                    if (nameTest(input)) 
                    {
                        formAddError(input); 
                        error++;
                    }
                }
            }
            else if (input.classList.contains('_email'))
            {
                if(emailTest(input))
                {
                    formAddError(input);
                    error++;
                }
  
            }
            else if(input.classList.contains('_tel'))
            {
                if(telTest(input))
                {
                    formAddError(input);
                    error++;
                }
            }
            else
            {
                if(input.value === '')
                {
                    formAddError(input);
                    error++;
                }
            } 
        }
        return error;
    };
    function formAddError(input) 
    {
        input.parentElement.classList.add('_error');
        input.classList.add('_error');
    };
    function formRemoveError(input) 
    {
        input.parentElement.classList.remove('_error');
        input.classList.remove('_error');
    };
    function emailTest(input){
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(input.value);
    };
    function nameTest(input){
        return !/^[а-яА-ЯёЁa-zA-Z ]+$/.test(input.value);
    };
    function telTest(input){
        return !/^[\s()+-]*([0-9][\s()+-]*){6,20}$/.test(input.value)
    };
    return (
            <div className="container text-center">
                <form id="form" className="col col-md-6 mx-auto form_block" ref={form} onSubmit={sendEmail}>
                    <h1>Напишите нам</h1>
                        <div className="form_field">
                    <label className="form_label text-start">Имя:</label>
                            <input className="form_input _req _name" name="Name" type="text" size="30" placeholder="Имя"/>
                        </div>
                        <div className="form_field">
                            <label className="form_label text-start">Ваша почта:</label>
                            <input className="form_input _req _mail" name="Email" type="email" size="30" placeholder="Электронная почта"/>
                        </div>
                        <div className="form_field">
                    <label className="form_label text-start">Номер телефона:</label>
                            <input className="form_input _req _tel" name="Tel" type="tel" size="30" placeholder="Номер телефона"/>
                        </div>
                        <div className="form_field">
                    <label className="form_label text-start">Сообщение:</label>
                            <textarea id="message" className="form_input _req _message" name="Message" type="text" size="250" placeholder="Сообщение"/>
                            <button className="button-rounded" type="submit">Выполнить</button>
                        </div>
                         
                </form>
            </div>
        );
};
export {ContactUsForm};
const Iframe = (props) =>
{
    return(

        <iframe 
            src={props.url}
            width={props.width} 
            height={props.height}
            className={props.className}
        >

        </iframe>
    );
}

export {Iframe};
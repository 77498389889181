import { CompanyHeader } from "../../SimpleComponents/CompanyHeader";
import { WorkSteps, WorkFacts } from "../../SimpleComponents/WorkSteps/WorkSteps.jsx";
import { ControlledCarousel } from "../../SimpleComponents/ControlledCarousel";
import Emblem from "../../../Images/Emblems/Emblem USK Builder.png";
import "./Improvement.scss";


export const Improvement = () => {

    return (
        <section className="container">
            <CompanyHeader 
            title="Благоустройство" 
            src={Emblem} 
            description ="УСК-Строитель занимается благоустройством муниципальных и частных объектов."
            number = " +7 (983) 413-87-57"
            email = " usk-stroitel2021@mail.ru">
                <section>
                    <WorkSteps 
                        firstStep = "Обращение"
                        firstStepInfo = ""
                        secondStep = "Оплата" 
                        secondStepInfo = "Оплатите счет любым удобным для вас способом." 
                        thirdStep = "Доставка" 
                        thirdStepInfo = "Доставим в любое удобное для вас время и место." />
                </section>
                <section className="row-facts green-background mt-3 mb-3">
                    <WorkFacts icon="bi bi-clock" title="Быстро" />
                    <WorkFacts icon="bi bi-clock" title="Надежно" />
                    <WorkFacts icon="bi bi-clock" title="Качественно" />
                </section>
                <section className="p-5">
                    <ControlledCarousel />
                </section>
            </CompanyHeader>
            <section>
            <h3 className="text-center">Данные не загрузились...</h3>
            </section>
        </section>
    );
}
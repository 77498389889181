import "./About.scss";
import Director from "../../../Images/png-clipart-computer-icons-user-profile-synonyms-and-antonyms-android-android-computer-wallpaper-monochrome.png";

function About() {

    return (

        <div className="container">
            <h2 className="pt-5">ГК-Строитель - это молодая развивающаяся группа компаний. За короткий срок мы достигли определенных высот на рынке, став надежными партнерами для наших клиентов.</h2>
            <h1 className="pt-5 pb-3">Пару слов от нашего директора</h1>
            <div className="row pb-5">
                <div className="col">
                    <img className="w-75" src={Director}/>
                </div>
                <div className="col">
                    <p className="fs-5"> <b>- "Качество это когда возвращается покупатель, а не товар!" </b></p>
                </div>
            </div>
        </div>

    );
}
export { About };
import { Modal } from "react-bootstrap";
import { Button } from "../../SimpleComponents/Button/Button";
import { useState } from "react";

export const ModalBootstrap = ({children}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Заказать
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Новый заказ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer className="m-auto">
                    <Button onClick={handleClose}>
                        Отправить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
import { useState } from "react";
import "./Button.scss";

export const Button = ({children, onClick}) => {
    const [backgroundColor, setBackgroundColor] = useState([]);
    return(
        <button className="button" onClick={onClick}>
            <span>{children}</span>
        </button>
    )
}
import { Link } from "react-router-dom";
import Coal from "../../Images/NavbarIcon/construction-wheelbarrow-svgrepo-com.svg";
import Machinery from "../../Images/NavbarIcon/construction-truck-vehicle-svgrepo-com.svg";
import Improvement from "../../Images/NavbarIcon/constructor-hat-helmet-protection-worker-svgrepo-com.svg";
import Concrete from "../../Images/NavbarIcon/concrete-svgrepo-com.svg"
import "./NavBar.scss";

const NavBar = () =>
{
    return(
        <div className="text-center">
            <nav className="container-fluid navbar navbar-expand-lg navbar-body">
                <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                        <figure>
                            <Link className="text-decoration-none text-black" to="/Уголь"> 
                                <img src={Coal} width="70px" height="70px"/>
                                <figcaption>Реализация угля</figcaption>
                            </Link>
                        </figure>
                    </li>
                    <li className=" nav-item">
                        <figure>
                        <Link className="text-decoration-none text-black" to="/Техника">
                            <img src={Machinery} width="70px" height="70px"/>
                            <figcaption>Спецтехника</figcaption>
                        </Link>
                        </figure>
                    </li>
                    <li className="nav-item">   
                        <figure>
                            <Link className="text-decoration-none text-black" to="/Благоустройство"> 
                                <img src={Improvement} width="70px" height="70px"/>
                                <figcaption>Благоустройство</figcaption>
                            </Link>
                            
                        </figure>
                    </li>
                    <li className="nav-item">
                        <figure>
                        <Link className="text-decoration-none text-black" to="/БетонныеИзделия">
                            <img src={Concrete} width="70px" height="70px"/>
                            <figcaption>Бетонные изделия </figcaption>
                        </Link>
                        </figure>
                    </li>
                </ul>
            </nav>
        </div>
    );



}

export {NavBar};
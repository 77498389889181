import "./CompanyHeader.scss";
const CompanyHeader = (props) => {

    return (
        <section className="container">
            <h1 className="pb-3">{props.title}</h1>
            <section className="row">
                <p className="col-8">{props.description}</p>
                <img className="img-company-default col-4" src={props.src} alt="Logo"/>
            </section>
            <section>
            {props.priceFile}
            </section>
            <section className="pt-3">
                <ul className="list-unstyled">
                    <h6>Наши контакты</h6>
                    <li><i className="bi bi-telephone-fill"/> <i className="bi-whatsapp text-success"/> <i className="bi-telegram text-primary"/>{props.number}</li>
                    <li><i className="bi bi-envelope-at-fill"/>{props.email}</li>
                </ul>
            </section>
          {props.children}
        </section>
    );
}
export { CompanyHeader };
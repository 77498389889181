import { Iframe } from "../../../SimpleComponents/Iframe";
import "./Map.scss"

const Map = () => {
    return (

        <div className="container text-center">
            <h1 className="pb-3 pt-5">Наше местоположение</h1>
            <div className="row">
                <Iframe
                    url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d854.0700170702675!2d103.67491940092967!3d52.7380603857412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d070db71ba973af%3A0xba9827173d3d452!2z0KPQodCe0JvQrNCVLdCf0KDQntCc0KLQldCl!5e0!3m2!1sru!2sru!4v1677481961285!5m2!1sru!2sru"
                    width ="1000px"
                    height = "500px"
                />
            </div>
        </div>
    );
}

export { Map };
export const CoalData = [

    {
        title: "Размер фракции:",
        unit: "мм"
    },
    {
        title: "Теплота сгорания:",
        unit: "ккал/кг"
    },
    {
        title: "Влага:",
        unit: "% Wtr"
    },
    {
        title: "Зольность:",
        unit: "%"
    },
    {
        title: "Сера:",
        unit: "%"
    },
    {
        title: "Летучие:",
        unit: "%"
    },
]
export const TruckData = [
    {
        title: "Грузоподъемность:",
        unit: "т"
    },
    {
        title: "Объем кузова:",
        unit: "м3"
    }
]
export const LoaderData = [
    {
        title: "Грузоподъемность:",
        unit: "т"
    },
    {
        title: "Объем ковша:",
        unit: "м3"
    },
    {
        title: "Масса:",
        unit: "т"
    },
    {
        title: "Высота стрелы:",
        unit: "м"
    }
]
export const ConcreteData = [
    {
        title: "Размеры:",
        unit: "см"
    }
]
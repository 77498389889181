
import { Form } from "react-bootstrap";
import { ModalBootstrap } from "../Modal/ModalBootstrap/ModalBootstrap";
import "./Item.scss";

const Item = (props) => {
    return (
        <section className={props.className}>
            <div className="card card-size text-center">
                <section class="card-header">
                    <h4>{props.name}</h4>
                    <img src={props.src} className="card-img-top" alt={props.name} />
                </section>
                <section className="card-body">
                    <ul className="list-group list-group-flush">
                        {props.children}
                    </ul>
                    <ModalBootstrap>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Ваше имя</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Иван Иванов"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Номер телефона</Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="+7(***)-***-**-**"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Ваша эл. почта</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Ваш заказ</Form.Label>
                            <Form.Control
                                type="text"
                                value={props.name}
                                disabled
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Кол-во</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Кол-во"
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                    </ModalBootstrap>
                </section>
            </div>
        </section>
    );
}
export { Item };
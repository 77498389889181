import { Item } from "../../SimpleComponents/Item";
import { ItemDescription } from "../../SimpleComponents/ItemDescription";
import { variables } from "../../Data/Variables";
import { ConcreteData } from "../../Data/ItemsData";
import { CompanyHeader } from "../../SimpleComponents/CompanyHeader";
import { WorkFacts, WorkSteps } from "../../SimpleComponents/WorkSteps/WorkSteps";
import { useState, useEffect } from "react";
import Emblem from "../../../Images/Emblems/Emblem UZK Stone.png"
import PriceFile from "../../../DownloadFiles/Price-Uzk-Kamen.pdf"
import "./Noun.scss";


export const Noun = () => {

    const [nounData, setNounData] = useState([])
    const [loading, setLoading] = useState(false)

    const photoPath = variables.PHOTO_URL

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const response = await fetch(variables.API_URL + 'realizationNoun')
            const data = await response.json()
            setNounData(data)
            setLoading(false)
        }

        fetchData()
    }, [])
    const nounsGrid = nounData.map((noun) => (
        <Item className="col" key={noun.NounId} src={photoPath + noun.NounImage} name={noun.NounName}>
            <ItemDescription title={ConcreteData[0].title} value={noun.NounSize} unit={ConcreteData[0].unit} />
        </Item>))
    return (

        <>
            <section className="container">
                <CompanyHeader 
                title="Реализация бетонных изделий" 
                description = "УЗК-Камень занимается производством бетонных изделий в соответствии с действующими: ГОСТами, Сериями, СНиПами"
                src={Emblem}
                number = " +7 (904) 137-90-00"
                email = " uzk-kamen@mail.ru"
                priceFile = {
                    <>
                        <h6>Наш прайс лист</h6>
                        <a href={PriceFile} download>Скачать файл</a>
                    </>
                }>
                    <section>
                        <WorkSteps 
                            firstStep = "Обращение"
                            firstStepInfo = "Оформим заявку при первом вашем обращении."
                            secondStep="Оплата" 
                            secondStepInfo="Оплатите счет любым удобным для вас способом." 
                            thirdStep="Доставка" 
                            thirdStepInfo="Доставим в любое удобное для вас время и место." />
                    </section>
                    <section className="row-facts green-background mt-3 mb-3">
                        <WorkFacts icon="bi bi-clock" title="Качественные материалы" />
                        <WorkFacts icon="bi bi-credit-card" title="Любой способ оплаты" />
                        <WorkFacts icon="bi bi-star" title="Квалифицированные работники" />
                    </section>
                </CompanyHeader>
            </section>
            <section className="container text-center">
                <h1 className="p-5">Ассортимент</h1>
                <div className="row">
                    {!loading && nounsGrid}
                    <h3>Данные не загрузились...</h3>
                </div>
            </section>
        </>

    );
}



import Complite from "../../../Images/NavbarIcon/checkmark-complete-edit-svgrepo-com.svg";
import "./WorkSteps.scss"

export const WorkSteps = (props) => {
    return (
        <section className="row complite-steps">
            <h2 className="pb-3">Доставка в 3 шага</h2>
            <div className="col">
                <img className="img-complite" src={Complite} alt="Complite"/>
                <p className="like-h3">{props.firstStep}</p>
                <p className="default-p">{props.firstStepInfo}</p>
            </div>
            <div className="col">
                <img className="img-complite" src={Complite} alt="Complite"/>
                <p className="like-h3">{props.secondStep}</p>
                <p className="default-p">{props.secondStepInfo}</p>
            </div>
            <div className="col">
                <img className="img-complite" src={Complite} alt="Complite"/>
                <p className="like-h3">{props.thirdStep}</p>
                <p className="default-p">{props.thirdStepInfo}</p>
            </div>
        </section>
    )
}
export const WorkFacts = (props) => {

    return (
        <section className="block-fact">
                <i className={`icon  ${ props.icon }`}  />
                <p className="default-p">{props.title}</p>
        </section>
    )
}
import { Link } from "react-router-dom";
import GK from "../../../Images/Logo/LogoGkHeader.png";
import "./Header.scss";

const Header = () => {

    return (
        <header className="container-fluid header">
            <div className="row">
                <div className="col-sm-3">
                    <Link to = "/"><img className="img-company-default" src={GK} alt="Logo"></img></Link>
                </div>
                <div className="col-sm-5 my-auto">
                    <ul className="row row-cols-sm list-unstyled text-nowrap">
                        <li className="col"><Link className="text-decoration-none text-black" to="/">Главная</Link></li>
                        <li className="col"><Link className="text-decoration-none text-black" to="/Онас">О компании</Link></li>
                        {/* <li className="col"><Link className="text-decoration-none text-black" to="/Вакансии">Вакансии</Link></li> */}
                        <li className="col"><Link className="text-decoration-none text-black" to="/Контакты">Контакты</Link></li>
                    </ul>
                </div>
                <div className="col-sm-4 my-auto">
                    <ul className="list-group list-unstyled">
                        <li><i class="bi bi-telephone-fill"></i> 8 (983) 413-87-57 <i className="bi-whatsapp text-success"></i> <i className="bi-telegram text-primary"></i></li>
                        <li><i class="bi bi-building"></i> Иркутская область, г. Усолье-Сибирское</li>
                        <li><i class="bi bi-envelope-at-fill"></i> usk-stroitel2021@mail.ru</li>
                    </ul>
                </div>
            </div>
        </header>
    )
}
export { Header };
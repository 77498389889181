import React from "react";
import { Routes, Route } from "react-router-dom";
import { Main } from "./Components/Pages/Main Page/Home";
import { About } from "./Components/Pages/About/About";
import { Contacts } from "./Components/Pages/Contacts/Contacts";
import { Layout } from "./Components/Layout/Layout";
import { Coals } from "./Components/Pages/Coals/Coals";
import { Machinery } from "./Components/Pages/Machinery/Machinery";
import { Noun } from "./Components/Pages/Noun/Noun";
import { Improvement } from "./Components/Pages/Improvement/Improvement";
// import { Vacancy } from "./Components/Pages/Vacancy/Vacancy";
// import { Administartion } from "./Components/Pages/Admin dashboard/Admin";




function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Main />} />
                <Route path="Онас" element={<About />} />
                <Route path="Контакты" element={<Contacts />} />
                <Route path="Уголь" element={<Coals />} />
                <Route path="Техника" element={<Machinery />} />
                <Route path="БетонныеИзделия" element={<Noun />} />
                {/* <Route path="Вакансии" element={<Vacancy />} /> */}
                <Route path="Благоустройство" element={<Improvement />} />
                {/* <Route path="Administration" element={<Administartion />} /> */}
            </Route>
        </Routes>
    );
}
export default App;
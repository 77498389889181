import { GroupCompany } from "./GroupCompany";

const Main = () => {
    return (
        <div>
            <GroupCompany />
        </div>
    );
}

export { Main };
import { useState, useEffect } from "react";
import { Item } from "../../SimpleComponents/Item";
import { ItemDescription } from "../../SimpleComponents/ItemDescription";
import { CompanyHeader } from "../../SimpleComponents/CompanyHeader";
import { LoaderData, TruckData } from "../../Data/ItemsData";
import { variables } from "../../Data/Variables";
import { WorkFacts, WorkSteps } from "../../SimpleComponents/WorkSteps/WorkSteps";

import Emblem from "../../../Images/Emblems/Emblem UUK Coal.png"
import "./Machinery.scss"


export const Machinery = () => {

    const [truckData, setTruckData] = useState([])
    const [loaderData, setLoaderData] = useState([])
    const [loading, setLoading] = useState(false)

    const photoPath = variables.PHOTO_URL

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const truckResponse = await fetch(variables.API_URL + 'realizationTruck')
            const loaderResponse = await fetch(variables.API_URL + 'realizationFrontLoader')
            const truckData = await truckResponse.json()
            const loaderData = await loaderResponse.json()
            setTruckData(truckData)
            setLoaderData(loaderData)
            setLoading(false)
        }

        fetchData()
    }, [])

    const trucksGrid = truckData.map((truck) => (

        <Item className="col" key={truck.TruckId} src={photoPath + truck.TruckImage} name={truck.TruckName}>
            <ItemDescription title={TruckData[0].title} value={truck.TruckPlatformVolume} unit={TruckData[0].unit} />
            <ItemDescription title={TruckData[1].title} value={truck.TruckLoadCapacity} unit={TruckData[1].unit} />
        </Item>))
    const frontLoaderGrid = loaderData.map((loader) => (

        <Item className="col" key={loader.LoaderId} src={photoPath + loader.LoaderImage} name={loader.LoaderName}>
            <ItemDescription title={LoaderData[0].title} value={loader.LoaderLoadCapacity} unit={LoaderData[0].unit} />
            <ItemDescription title={LoaderData[1].title} value={loader.LoaderBucketVolume} unit={LoaderData[1].unit} />
            <ItemDescription title={LoaderData[2].title} value={loader.LoaderWeight} unit={LoaderData[2].unit} />
            <ItemDescription title={LoaderData[3].title} value={loader.LoaderBoomHeight} unit={LoaderData[3].unit} />
        </Item>))
    return (

        <section className="container">

            <CompanyHeader 
                title="Аренда спецтехники по Иркутской области" 
                description="Аренда техники от нас" 
                src={Emblem} 
                number = "+7 (983) 413-87-57"
                email = "usk-stroitel2021@mail.ru"
                >
                <section>
                    {/* <WorkSteps 
                        firstStep = "Обращение"
                        firstStepInfo = "Оформим заявку при первом вашем обращения к нам." 
                        secondStep="Заключение договора" 
                        secondStepInfo="Оплатите счет любым удобным для вас способом." 
                        thirdStep="Работа" 
                        thirdStepInfo="Доставим в любое удобное для вас время и место." /> */}
                </section>
                <section className="row-facts green-background mt-3 mb-3">
                    <WorkFacts icon="bi bi-clock" title="Срочная подача машины" />
                    <WorkFacts icon="bi bi-piggy-bank" title="Предложим лучшую стоимость" />
                    <WorkFacts icon="bi bi-star" title="Квалифицированные водители" />
                </section>
            </CompanyHeader>
            <section className="text-center">
                <h1 className="p-2">Автопарк</h1>
                <h2 className="p-5">Самосвалы</h2>
                <div className="row w-75">
                    {!loading && trucksGrid}
                    <h3>Данные не загрузились...</h3>
                </div>
                <h2 className="p-5">Погрузчики</h2>
                <div className="row w-75">
                    {!loading && frontLoaderGrid}
                    <h3>Данные не загрузились...</h3>
                </div>
            </section>
        </section>
    );
}



import { Link } from "react-router-dom";
import "./Footer.scss"


const Footer = () => {
    return (

        <footer className="footer">
            <div className="one"><Link className="text-decoration-none text-black w-auto" to="/Онас">О компании</Link></div>
            <div className="two"><Link className="text-decoration-none text-black w-auto" to="/Вакансии">Бетонные изделия </Link></div>
            <div className="three"><Link className="text-decoration-none text-black w-auto" to="/Контакты">Контакты</Link></div>
            <div className="four"><Link className="text-decoration-none text-black w-auto" to="/Уголь"> Уголь</Link></div>
            <div className="five"><Link className="text-decoration-none text-black w-auto" to="/Благоустройство"> Благоустройство</Link></div>
            <div className="six"><Link className="text-decoration-none text-black w-auto" to="/Техника"> Спецтехника </Link></div>
        
            <div className="seven"> Отдел продаж: </div>
            <div className="eight"> 8(983)413-87-57 </div>
            <div className="nine"> usk-stroitel2021@mail.ru </div>

        </footer>
    );

}
export { Footer };
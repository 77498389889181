import { ContactUsForm } from "./Email/ContactUsForm";
import {Map} from "./Map/Map";

const Contacts = () =>
{

    return(
        <div>
            <ContactUsForm/>
            <Map/>
        </div>

    );

}
export {Contacts};
import "./GroupCompany.scss"
import Logo from "../../../Images/Logo/hd/gkbuilder.png";
import Coal from "../../../Images/Logo/hd/coal.png";
import Builder from "../../../Images/Logo/hd/builder.png";
import Stone from "../../../Images/Logo/hd/stone.png";
import { Link } from "react-router-dom";


const GroupCompany = () => {
    return (

        <section className="container">
            <div className="groupGrid ">

                <div id="item" className="group_company_builder">
                    <img src={Logo} alt="gk-builder"></img>
                    <p className="fs-5"> Холдинг группы компаний <b>"Строитель"</b> состоит из следующих организаций:  </p>
                    <ul className="list-unstyled fs-5">
                        <li><b>УУК Уголь</b>- отптово-розничная продажа угля. Аренда и услуги спецтехники.</li>
                        <li><b>УСК Строитель</b> - строительство, благоустройство, капитиальный ремнот объектов.</li>
                        <li><b>УЗК Камень</b> - производство товарного бетона, изделий из бетона.</li>
                    </ul>
                    <p className="like-h3"> C услугами компаний вы можете ознакомиться ниже. </p>
                </div>

                <div id="item" className="uuk_coal">
                    <img src={Coal} alt="coal" />

                    <div id="isHover" className="group-border">
                        <Link className="text-decoration-none text-black" to="/Уголь">
                            <p className="white-border">Реализация угля</p>
                        </Link>
                        <Link className="text-decoration-none text-black" to="/Техника">
                            <p className="white-border">Аренда спецтехники</p>
                        </Link>
                    </div>


                </div>
                <div id="item" className="usk_builder">
                    <img src={Builder} alt="builder" />
                    <div id="isHover" className="group-border">
                        <Link className="text-decoration-none text-black" to="/Благоустройство">

                            <p className="white-border">Благоустройство</p>
                        </Link>

                        <Link className="text-decoration-none text-black" to="/Благоустройство">
                            <p className="white-border">Капитальный ремонт</p>
                        </Link>
                    </div>
                </div>
                <div id="item" className="uzk_stone">
                    <img src={Stone} alt="rock" />
                    <div id="isHover" className="group-border">
                        <Link className="text-decoration-none text-black" to="/БетонныеИзделия">
                            <p className="white-border">Бетонные изделия</p>
                        </Link>
                        <Link className="text-decoration-none text-black" to="/БетонныеИзделия">
                            <p className="white-border">Товарный бетон</p>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );


}
export { GroupCompany };
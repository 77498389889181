import { Outlet } from "react-router-dom";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { NavBar } from "./NavBar";
const Layout = () => {

    return (
        <div className="layout fontSize-12">
            <Header/>
            <NavBar />
            <Outlet />
            <Footer />
        </div>
    );

}
export { Layout }